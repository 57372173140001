import { useState } from "react";
import "./App.css";
import LineChart from "./LineChart";
import startShow from "./lightShow";

function App() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const checkIsDataLoaded = () => {
    let inputData = document.getElementById("csv-input").value;
    if (inputData !== null && inputData !== undefined && inputData !== "") {
      setCsvData(inputData);
      setDataLoaded(true);
    }
  };
  return (
    <div className="d-flex flex-column align-items-center text-light gap-5 p-5 justify-content-between">
      <header className="fs-1">Light demo</header>
      <main className="d-flex flex-column gap-5 w-100 align-items-center">
        <section className="d-flex flex-row gap-5 w-100 p-5">
          <div className="w-100 d-flex flex-column gap-2">
            <p>Please enter your test sequence in this format: </p>
            <textarea
              id="csv-input"
              rows={15}
              cols={40}
              placeholder="M1,[Exec 1.1 at 100 fade 0],0:00:03:00&#10;M2,[Exec 1.1 at 15 fade 0],0:00:05:00&#10;M3,[Exec 1.1 at 75 fade 0],0:00:07:00&#10;M4,[Exec 1.1 at 50 fade 0],0:00:10:00&#10;M5,[Exec 1.1 at 60 fade 0],0:00:12:00"
            ></textarea>
            <div className="d-flex flex-row gap-3">
              <button
                className="btn btn-primary"
                onClick={() => {
                  checkIsDataLoaded();
                }}
              >
                Load data
              </button>
              <button
                className="btn btn-primary"
                disabled={!dataLoaded}
                onClick={() => {
                  startShow();
                }}
              >
                Start show
              </button>
            </div>
          </div>
          <div id="lamp" className="w-100 border-top rounded">
            <div id="lamp-top"></div>
            <div id="lamp-middle"></div>
            <div id="lamp-bottom"></div>
            <div id="glow"></div>
          </div>
        </section>
        <section className="d-flex flex-row gap-5 w-100 justify-contents-between p-5">
          <div className="w-100 bg-white text-dark rounded">
            <LineChart data={csvData}></LineChart>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
