import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const LineChart = (props) => {
  const data = props;
  const [series, setSeries] = useState([
    {
      name: "Intensity",
      data: [[0, 0]],
    },
  ]);
  const [options, setOptions] = useState({
    xaxis: {
      type: "numeric",
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ["#132666b3"],
  });
  useEffect(() => {
    if (data.data !== null) {
      function parseDuration(durationString) {
        const [hours, minutes, seconds] = durationString.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
      }

      const SEQUENCE = data.data.split("\n").map((item) => {
        const [name, info, unparsedDuration] = item.split(",");
        let intensity = Number(info.match(/at (\d+)/)[1]);
        let fade = Number(info.match(/fade (\d+)/)[1]);
        const parsedDuration = parseDuration(unparsedDuration);
        let startTime = parsedDuration;

        return { name, intensity, fade, startTime };
      });
      SEQUENCE.unshift({ name: "M0", intensity: 0, fade: 0, startTime: 0 });
      var series = [
        {
          name: "Intensity",
          data: [[0, 0]],
        },
      ];
      var prevItem;
      SEQUENCE.forEach((item, index) => {
        if (index !== 0) {
          series[0].data.push([item.startTime, prevItem.intensity]);
          series[0].data.push([item.startTime + item.fade, item.intensity]);
        }
        prevItem = item;
      });
      // const series = [
      //   {
      //     name: "Light show sequence",
      //     data: [
      //       [0, 0],
      //       [5, 0], //M1
      //       [5, 100], //M1
      //       [10, 100], //M1M2
      //       [14, 75], //M2
      //       [20, 75], //M2M3
      //       [22, 25],
      //       [25, 25],
      //       [25, 0],
      //       [30, 0],
      //     ],
      //   },
      // ];
      const options = {
        xaxis: {
          type: "numeric",
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: ["#132666b3"],
      };
      setOptions(options);
      setSeries(series);
    }
  }, [data]);

  return (
    <div className="chart">
      {data !== null && data !== undefined ? (
        <Chart options={options} series={series} type="line" height="350" />
      ) : (
        <p>Please enter csv data to show chart.</p>
      )}
    </div>
  );
};

export default LineChart;
